<template>
  <div>
    <router-link class="come-back" to="/admin/country">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование Страны' : 'Создание Страны' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="addCountry" ref="addCountry">
      <div>
        <div class="content-sb">
          <p>Страна<span>*</span></p>
          <input required placeholder="Введите название страны" type="text" name="title" v-model="form.title" class="input blue">
        </div>
        <div class="content-sb">
          <p>Страна (Англ)</p>
          <input placeholder="Введите название страны (Англ)" type="text" name="titleEng" v-model="form.titleEng" class="input blue">
        </div>
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/country" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createUser',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailCountry : {
        title: '',
        titleEng: ''
      }
    }
  },
  methods: {
    addCountry () {
      const formData = new FormData(this.$refs.addCountry)
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editCountry', this.form)
      } else {
        this.$store.dispatch('AddCountry', formData)
      }
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailCountry === null) {
      this.$router.push('/admin/country')
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
